<template>
  <div class="deal-width-device">
    <x-table
      :no-data-text="CA('dealDevice_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadEnter="loadEnter"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="120">
        <Row>
          <Col span="11">
            <FormItem label="设备名称" prop="name">
              <Input v-model="form.name"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="设备类型" prop="category">
              <Select v-model="form.category">
                <Option
                  v-for="item in categoryList"
                  :value="item.id + '/' + item.name"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="所属养殖场" prop="breed">
              <!-- <Cascader
                :data="farmScene"
                v-model="form.breed"
                @on-change="breedChange"
              ></Cascader> -->
              <Select @on-change="breedChange" v-model="form.breed">
                <Option
                  v-for="(item, index) in farmSceneData"
                  :value="item.id"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="数量" prop="num">
              <Input v-model="form.num"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="单位" prop="unit">
              <Input v-model="form.unit"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="使用状态" prop="status">
              <Select v-model="form.status">
                <Option :value="0">闲置</Option>
                <Option :value="1">工作</Option>
                <Option :value="2">废弃</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="投入使用时间" prop="firstUseTime">
              <DatePicker
                style="width: 278px"
                type="date"
                v-model="form.firstUseTime"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="处理能力" prop="deal">
              <Input v-model="form.deal"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="规格" prop="spec">
              <Input v-model="form.spec"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="设备位置" prop="thirdLongLat">
              <Input
                v-model="form.thirdLongLat"
                disabled
                :placeholder="
                  form.breed ? '点击右侧图标选择设备位置' : '请先选择养殖场'
                "
              >
                <span
                  slot="append"
                  class="iconfont"
                  @click="mapModalShow"
                  :style="{
                    cursor: form.breed ? 'pointer' : 'not-allowed',
                  }"
                >
                  &#xe648;
                </span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="设备图片" prop="img">
              <upload-image
                v-model="form.img"
                :max="1"
                :clearFlag="!modal.show"
              ></upload-image>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.loading" @click="submit"
          >提交</Button
        >
        <Button @click="modal.show = false">取消</Button>
      </p>
    </Modal>
    <Modal v-model="mapModel" title="选择设备位置" fullscreen>
      <search-map
        v-if="mapModel"
        :currentData="currentData"
        :backgroundPosition="backgroundPosition"
        @back-location="dealMapData"
      ></search-map>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="() => (mapModel = false)">提交</Button>
        <Button @click="() => (mapModel = false)">取消</Button>
      </p>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
  </div>
</template>

<script>
import CU from "@/common/util";
import searchMap from "@/components/searchMap";
import moment from "moment";
import pictureView from "@/components/picture_view";
export default {
  name: "",
  components: {
    searchMap,
    pictureView,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "设备名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "设备图片",
            width: 100,
            align: "center",
            render: (h, { row }) => {
              if (row.img.substring(5) == "undefined") return <span>--</span>;
              return (
                <img
                  src={this.getImgUrl(row.servicePath, row.img)}
                  onClick={() => this.lookPicture(row.servicePath + row.img)}
                  style="width: 35px;height: 35px;cursor:pointer"
                />
              );
            },
          },
          {
            title: "设备类型",
            minWidth: 100,
            key: "categoryName",
          },
          {
            title: "所属养殖场",
            minWidth: 110,
            render: (h, { row }) => {
              // return <span>{row.farmName + "/" + row.sceneName}</span>;
              return <span>{row.farmName}</span>;
            },
          },
          {
            title: "数量",
            width: 100,
            render: (h, { row }) => {
              return <span>{row.num + "/" + row.unit}</span>;
            },
          },
          {
            title: "使用状态",
            width: 100,
            render: (h, { row }) => {
              return (
                <span>
                  {row.status == 0 ? "闲置" : row.status == 1 ? "工作" : "废弃"}
                </span>
              );
            },
          },
          {
            title: "第一次投入使用时间",
            width: 180,
            key: "firstUseTime",
            render: (h, { row }) => {
              return <span>{row.firstUseTime.split(" ")[0]}</span>;
            },
          },
          {
            title: "处理能力",
            minWidth: 100,
            key: "deal",
          },
          {
            title: "规格",
            minWidth: 100,
            key: "spec",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("dealDevice_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("dealDevice_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        category: "",
        breed: [],
        unit: "",
        status: "",
        firstUseTime: "",
        deal: "",
        spec: "",
        thirdLongLat: "",
        farmId: "",
        sceneId: "",
        siteType: "2",
        img: "",
        num: "",
      },
      rules: {
        name: [{ required: true, message: "请填写设备名称" }],
        category: [{ required: true, message: "请选择设备类型" }],
        breed: [{ required: true, message: "请选择所属养殖场" }],
        unit: [{ required: true, message: "请填写设备单位" }],
        status: [{ required: true, message: "请选择设备使用状态" }],
        firstUseTime: [{ required: true, message: "请选择第一次投入使用时间" }],
        deal: [{ required: true, message: "请填写设备处理能力" }],
        spec: [{ required: true, message: "请填写设备规格" }],
        thirdLongLat: [{ required: false, message: "请选择设备位置" }],
        num: [
          { required: true, message: "请填写数量" },
          {
            validator(rule, value, callback) {
              if (!CU.validateNumber(value, 0, undefined, 0)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "数量只能是正整数",
          },
        ],
      },
      categoryList: [],
      farmScene: [],
      farmSceneData: [],

      mapModel: false,
      currentData: null,

      // 选中的养殖区数据
      backgroundPosition: [],

      picture: {
        show: false,
        src: "",
      },
      
      thirdLongLat: ""
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增设备",
            ca: "dealDevice_add",
          },
          loadModel:{
            url:'https://castoff.hogdata.cn/app/设备.xlsx'
          },
          loadEnter: {
            loadName: "导入",
            ca: "",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "设备名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "设备类型",
              component: "select",
              field: "categoryId",
              defaultValue: "",
              data: this.categoryList,
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "设备状态",
              component: "select",
              field: "status",
              defaultValue: "",
              data: [
                { id: "0", name: "闲置" },
                { id: "1", name: "工作" },
                { id: "2", name: "废弃" },
              ],
              parameterField: "id",
              showField: "name",
            },
            {
              conditionName: "所属养殖场",
              component: "select",
              field: "farmId",
              defaultValue: "",
              data: this.farmSceneData,
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    getMapData(){
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2"
      })
      .then((res) => {
        this.thirdLongLat = this.form.thirdLongLat = res.list[0].thirdLongLat;
      });
    },
    mapModalShow() {
      this.currentData = this.form.thirdLongLat;
      this.mapModel = true;
    },
    dealMapData(positionData) {
      this.form.thirdLongLat = positionData.thirdLongLat;
    },
    //养殖区
    breedChange(value) {
      let backgroundPosition = [];
      this.backgroundPosition = [];
      if (!value) return;
      let item = this.farmSceneData.find((item) => item.id == value);
      item &&
        item.mapPosition &&
        backgroundPosition.push(...JSON.parse(item.mapPosition));
      // for (let i = 0; i < selectedData.length; i++) {
      //   let item = selectedData[i];
      //   item.mapPosition &&
      //     backgroundPosition.push(...JSON.parse(item.mapPosition));
      // }
      this.backgroundPosition = backgroundPosition;
      this.form.farmId = value[0];
      this.form.sceneId = value[1];
    },
    add() {
      this.modal = {
        show: true,
        title: "新增设备",
        submitLoading: false,
      };
    },
    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    getList() {
      if (!this.CA("dealDevice_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEAL_DEVICE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: "2",
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "13",
      }).then((res) => {
        this.categoryList = res.list;
      });
    },
    getBreedArea() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "2",
      }).then((res) => {
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList", [
          "mapPosition",
        ]);
        this.farmSceneData = res;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      
      let farm = this.farmScene.find((item) => item.value == row.farmId);
      let scene =
        row.sceneId && farm.children
          ? farm.children.find((item) => item.value == row.sceneId)
          : undefined;
      let backgroundPosition = [];
      if (farm && farm.mapPosition && scene && scene.mapPosition) {
        farm && backgroundPosition.push(...JSON.parse(farm.mapPosition));
        scene && backgroundPosition.push(...JSON.parse(scene.mapPosition));
      }
      this.backgroundPosition = backgroundPosition;
      this.form.breed = [row.farmId, row.sceneId];
      this.form.category = row.categoryId + "/" + row.categoryName;
      if (row.img == "imageundefined") {
        this.form.img = "";
      } else {
        this.form.img = this.getImgUrl(row.servicePath, row.img);
      }
      // this.getMapPosition(this.form.breed);
      this.modal = {
        show: true,
        title: "编辑设备",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.DEAL_DEVICE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    //导入
    loadEnter() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (e) => {
        if (!input.files || input.length === 0) return;
        let formdata = new FormData();
        formdata.append("file", input.files[0]);
        formdata.append("userId", localStorage.getItem("userId"));
        formdata.append("companyNo", localStorage.getItem("companyNo"));
        formdata.append("siteType", 2);
        this.$post(this.$api.DEAL_DEVICE.UPLOAD, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(() => {
          this.getList();
        });
      };
      input.click();
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        category: "",
        breed: [],
        unit: "",
        status: "",
        firstUseTime: "",
        deal: "",
        spec: "",
        thirdLongLat: "",
        farmId: "",
        sceneId: "",
        siteType: "2",
        img: "",
        num: "",
      };
      this.form.thirdLongLat = this.thirdLongLat;
      console.log('this.form',this.form,this.thirdLongLat);
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.firstUseTime = moment(this.form.firstUseTime).format(
          "YYYY-MM-DD"
        );
        //修改--选择养殖场
        params.farmId = this.form.breed;
        params.categoryId = this.form.category.split("/")[0];
        params.categoryName = this.form.category.split("/")[1];
        params.img = "image" + this.form.img.split("image")[1];
        delete params.breed;
        delete params.category;
        this.modal.submitLoading = true;
        this.$post(
          params.id ? this.$api.DEAL_DEVICE.EDIT : this.$api.DEAL_DEVICE.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      };
    },
  },
  mounted() {
    this.getMapData();
    this.getList();
    this.getCategory();
    this.getBreedArea();
  },
};
</script>

<style lang="less" scoped>
.deal-width-device {
  width: 100%;
  height: 100%;
}
</style>
